export interface Facility {
  facilityId: string;
  clientId: string;
  name: string;
  coverPhotoId: string;
  rACSPhotoId: string;
  address: string;
  address1: string;
  address2: string;
  suburb: string;
  state: string;
  postcode: string;
  phone: string;
  fax: string;
  emailAddress: string;
  contactFirstName: string;
  contactLastName: string;
  reportingDashboard: string;
  enableSecondSignature: boolean;
  hideNurseInitiated: boolean;
  timeoutMinutes: number;
  providerId: string;
  archive: boolean;
  email: string;
  externalId: string;
  residentCount: number;
  contactsId: string;
  racfNumber: string;
  staffCount: number;
  dateImported: Date;
  dateStaffImported: Date;
  scoupDate: Date;
  medegroupId: string;
  rACSNumber: number;
  managerId: string;
  contactId: string;
  insertDate: Date;
  insertUserId: string;
  updateDate: Date;
  updateUserId: string;
  deleteDate: Date;
  deleteUserId: string;
  patient: any; // Patient
  insertUser: any; //user;
  updateUser: any; //user;
  beds: number
}

export const facilityTableColumns = [
  'name',
  // 'racsNumber',
  'fullAddress',
  'phone',
  'fax',
  'email',
  'manager',
  'contact',
  'actions',
]

export const facilityUploadTableColumns = [
  'uploadName',
  'uploadedBy',
  'uploadDate',
  // 'fileName',
  'documentType',
]

export const facilityScoopTableColumns = [
  'insertDate',
  'totalResidents',
  'progress',
  'statusId',
]

export const testData = [{
  name: 'name',
  rACSNumber: 'rACSNumber',
  address: 'address',
  phone: 'phone',
  fax: 'fax',
  email: 'email',
  manager: 'manager',
  contact: 'contact',
  actions: 'actions',
}, {
  name: 'name',
  rACSNumber: 'rACSNumber',
  address: 'address',
  phone: 'phone',
  fax: 'fax',
  email: 'email',
  manager: 'manager',
  contact: 'contact',
  actions: 'actions',
}, {
  name: 'name',
  rACSNumber: 'rACSNumber',
  address: 'address',
  phone: 'phone',
  fax: 'fax',
  email: 'email',
  manager: 'manager',
  contact: 'contact',
  actions: 'actions',
}, {
  name: 'name',
  rACSNumber: 'rACSNumber',
  address: 'address',
  phone: 'phone',
  fax: 'fax',
  email: 'email',
  manager: 'manager',
  contact: 'contact',
  actions: 'actions',
}, {
  name: 'name',
  rACSNumber: 'rACSNumber',
  address: 'address',
  phone: 'phone',
  fax: 'fax',
  email: 'email',
  manager: 'manager',
  contact: 'contact',
  actions: 'actions',
}, {
  name: 'name',
  rACSNumber: 'rACSNumber',
  address: 'address',
  phone: 'phone',
  fax: 'fax',
  email: 'email',
  manager: 'manager',
  contact: 'contact',
  actions: 'actions',
},
]
